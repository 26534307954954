@import '../../../index.scss';

#lang__desktop {
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: solid #b82428 3px;
}

#lang__desktop button {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  padding-left: 8px;
  height: 20px;
  list-style: none;
  transition: transform 0.5s ease;
}

@media ((min-width: $mobileSE) and (max-width: $tablet)) {
  .menu__item a {
    margin-left: 12%;
    justify-content: left;
  }
  .menu__item img {
    margin-left: 0 !important;
    margin-right: 8%;
  }
}
