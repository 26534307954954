@import '../../../index.scss';

.start__project__progress__content {
  text-align: left;
}

.contact_form_block {
  input {
    background-position: center right 15px;
  }
}

// .start__project__step {
//   img {
//     margin-right: 15px;
//   }
// }

// .progress__bar {
//   margin-left: 15px;
// }

// .start__project__title_content {
//   h2 {
//     margin-left: 24px;
//   }
// }

// .start__project__progress__content {
//   margin-left: 65px;
//   & h3 {
//     padding-left: 15px;
//   }
//   & li {
//     padding-left: 15px;
//   }
// }

// .contact_form_content {
//   label {
//     input {
//       padding-left: 15px;
//     }
//   }
//   & img {
//     margin-right: 10px;
//   }
//   .contact_form_block {
//     &:nth-child(1) {
//       input {
//         background: right 10px bottom 50% no-repeat
//           url('../../../resources/icons/yourContact/nameUser.svg');
//       }
//     }
//     &:nth-child(2) {
//       input {
//         background: right 10px bottom 50% no-repeat
//           url('../../../resources/icons/yourContact/telephoneNumber.svg');
//       }
//     }
//     &:nth-child(3) {
//       input {
//         background: right 10px bottom 50% no-repeat
//           url('../../../resources/icons/yourContact/email.svg');
//       }
//     }
//   }
// }

// //  Intermediate version

// @media ((min-width: 600px ) and (max-width:859px)) {
//   .contact_form_block {
//     margin-left: 20% !important;
//   }
//   .contact_form_information_content {
//     p {
//       margin-top: 0.5% !important;
//       text-align: center;
//       width: 500px;
//     }
//   }
// }

// //  Intermediate version

// @media ((min-width: 421px) and (max-width:599px)) {
//   .contact_form_block {
//     margin-left: 14% !important;
//   }
//   .start__project__title_content {
//     h2 {
//       font-size: 2.1rem !important;
//     }
//   }
// }

// //  Intermediate version

// @media ((min-width: 375px ) and (max-width:450px)) {
//   .contact_form_block {
//     margin-left: 7% !important;
//   }
//   .contact_form_information_content {
//     & p {
//       font-size: 0.8rem !important;
//       text-align: center !important;
//       max-width: 325px !important;
//     }
//   }
// }

// // Intermediate version from Mobile to Tablet

// @media ((min-width: 421px) and (max-width: $tablet)) {
//   .start__project__container {
//     margin-left: 10%;
//   }
// }

// //  Intermediate version from Mobile SE

// @media ((min-width: 375px ) and (max-width:450px)) {
//   .start__project__container {
//     margin-left: 5%;
//   }
//   .start__project__step {
//     font-size: 0.9rem;
//   }
// }
