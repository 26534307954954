@import '../../../index.scss';

.card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 170px;
  width: 170px;
  @media (max-width: 500px) {
    scale: 0.9;
    width: 136px;
    height: 136px;
  }
  @media (min-width: 500px) {
    &:hover {
      box-shadow: 0px 0px 22px 2px rgba(0, 0, 0, 0.31);
      transition: all 0.7s;
    }
  }

  .arrow {
    @media (min-width: 500px) {
      position: absolute;
      height: 15px;
      width: 15px;
      top: 15px;
      right: 15px;
    }
    @media (max-width: 500px) {
      position: absolute;
      height: 15px;
      width: 15px;
      top: 2px;
      right: 8px;
    }
  }
}

.card__context {
  text-align: center;
  margin: auto auto;
  img {
    height: 76px;
    width: 76px;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(27, 27, 27);
    margin-bottom: 0;
  }
}

//  Intermediate version from Mobile SE

// .card {
//   margin-top: 15px;
//   margin-right: 5px;
//   margin-left: 5px;
