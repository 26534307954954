@import '../../../index.scss';

.section.left {
  & .image {
    box-shadow: -39px 40px 28px rgba(0, 0, 0, 0.17);
  }
}
.section.right {
  & .image {
    box-shadow: 39px 40px 28px rgba(0, 0, 0, 0.17);
  }
}

