@import '../../../index.scss';

.menu__list {
  @media (min-width: 1025px) {
    flex-direction: row-reverse;
  }
  .menu__link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }
}

// Menu Tablet version

@media ((min-width: $mobileSE) and (max-width: $tablet)) {
  .menu__item a {
    margin-right: 12%;
    justify-content: right;
  }
  .menu__item img {
    margin-right: 0 !important;
    margin-left: 8%;
  }
}
