@import '../../../index.scss';
*,
*::before,
*::after {
  box-sizing: inherit;
}

.menu a {
  color: #f4f4f4;
  position: relative;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

#lang__desktop {
  padding-left: 3px;
  list-style: none;
  width: 35px;
}

#lang__desktop button {
  color: #f4f4f4;
  position: relative;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a:active {
  color: #f4f4f4;
}
.menu hr {
  display: none;
}

.menu__item img {
  display: none;
}

@media (min-width: 1200px) {
  .menu__list a::before {
    display: flex;
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #b82428;
    top: 22px;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  .menu__list a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

#header {
  position: fixed;
  background-color: rgba(24, 24, 24, 0.845);
  backdrop-filter: blur(8px);
  height: 60px;
  width: 100vw;
  max-width: 1920px;
  z-index: 2;
}

.logo__container {
  display: flex;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.logo__img {
  height: 63px;
  transition: 0.5s;
}

#Home {
  display: none;
}

#Start {
  display: none;
}

.header__burger-btn {
  display: none;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 2;
  cursor: pointer;
}

.header__burger-btn span {
  display: block;
  position: absolute;
  width: 30px;
  height: 4px;
  border-radius: 30px;
  left: 5px;
  background-color: #f4f4f4;
  transition: background-color 0.3s, opacity 0.3s, transform 0.5s;
  will-change: transform;
}

.header__burger-btn:hover span {
  background-color: rgb(103, 103, 103);
}

.header__burger-btn span:nth-child(1) {
  transform: translateY(-10px);
}

.header__burger-btn span:nth-child(3) {
  transform: translateY(10px);
}

/* Крестик */

#header.open .header__burger-btn span {
  background-color: #f4f4f4;
}

#header.open .header__burger-btn span:nth-child(1) {
  transform: translateY(0) rotate(45deg);
}

#header.open .header__burger-btn span:nth-child(2) {
  opacity: 0;
}

#header.open .header__burger-btn span:nth-child(3) {
  transform: translateY(0) rotate(-45deg);
}

.menu__list {
  display: flex;
  align-items: center;
  color: #f4f4f4;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu__item:not(:last-child) {
  margin-right: 67px;
}
.logo__img:hover {
  transform: scale(1.2);
}

@media (min-width: 1575px) {
  #lang__desktop {
    display: flex;
    justify-content: left;
    align-items: center;
    border-left: solid #b82428 3px;
  }

  #lang__desktop button {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    padding-left: 8px;
    height: 20px;
    list-style: none;
    transition: transform 0.5s ease;
  }
  .menu__item__dropbuttons img {
    margin: auto;
    margin-top: 8px;
    margin-left: 13px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .menu__item__dropbuttons {
    display: flex;
  }

  #lang__mobile {
    display: none;
  }
}

@media (min-width: $mobileSE) and (max-width: 1574px) {
  #lang__mobile {
    display: flex;
    align-items: center;
    margin: auto;
    margin-left: 15px;
    padding: 0;
    border-left: solid #b82428 4px;
    width: 35px;
    height: 25px;
    list-style: none;
  }

  #lang__mobile button {
    font-size: 18px;
    display: flex;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    padding-top: 1.5px;
    padding-left: 8px;
    background-color: transparent;
    color: #f4f4f4;
    border: none;
    cursor: pointer;
  }

  .menu__item__dropbuttons img {
    cursor: pointer;
    margin: auto;
    margin-top: 7.5px;
    margin-left: 13px;
    width: 19px;
    height: 19px;
  }
  .menu__item__dropbuttons {
    display: flex;
  }

  #lang__desktop {
    display: none;
  }
}

// Menu Tablet version

@media (max-width: $tablet) {
  #lang__mobile {
    padding-left: 0px;
  }

  .header__burger-btn {
    display: block;
  }

  .header__container {
    padding-top: 5px;
  }
  .menu {
    display: flex;
    justify-content: center;
    position: absolute;
    background: left 50% top 10% no-repeat
      url('../../../resources/icons/logoMobile.svg');
    background-size: 220px;
    right: -390px;
    top: 0;
    width: 390px;
    height: 1380px;
    background-color: #121212;
    visibility: hidden;
    transition: transform 0.5s, visibility 0.5s, box-shadow 0.5s ease-out;
    will-change: transform;
    box-shadow: 0px 0px 1024px 1024px rgba(24, 24, 24, 0.422);
  }

  .menu hr {
    display: block;
    border: none;
    width: 350px;
    height: 1px;
    background-color: #323232;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .menu__item a {
    display: flex;
    // justify-content: left;
    align-items: center;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-decoration: none;
    margin-left: 40px;
  }

  .menu__item img {
    display: flex;
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }

  .menu__list {
    width: 120px;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 120%;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
  }
  .menu__link {
    display: flex;
    justify-content: center;
  }

  #header.open .menu {
    transform: translateX(-100%);
    visibility: visible;
  }

  #Lang {
    display: none;
  }

  #header {
    height: 70px;
  }

  #Home {
    display: flex;
  }

  #Start {
    display: flex;
  }
}

//  Intermediate version

@media (min-width: 769px) and (max-width: 834px) {
  @media (min-height: 428px) {
    .menu {
      background: left 50% top 10% no-repeat
        url('../../../resources/icons/logoMobile.svg');
      background-size: 220px;
      height: 100vh;
      width: 390px;
      right: -390px !important;
    }
    .menu__list {
      flex-direction: column-reverse;
      align-items: center;
      width: 120px;
      .menu__item {
        width: 350px;
      }
    }
  }
  .menu {
    background: left 50% top 13% no-repeat
      url('../../../resources/icons/logoMobile.svg');
    background-size: 220px;
    background-color: #121212;
    right: -390px;
  }
  .menu__list {
    margin-bottom: 55%;
  }
}

@media ((min-width: 501px) and (max-width: 768px)) {
  @media (min-height: 375px) {
    .menu {
      height: 100vh;
      width: 390;
      right: -390px !important;
    }
    .menu__list {
      flex-direction: column-reverse;
      align-items: center;
      width: 120px;
      .menu__item {
        width: 350px;
      }
    }
  }
  .menu {
    background: none;
    background-size: 220px;
    background-color: #121212;
    right: -390px;
  }
  .menu__list {
    margin-bottom: 30%;
  }
}
@media (min-width: 391px) and (max-width: 500px) {
  .menu {
    position: absolute;
    background: none;
    background-size: 180px;
    background-color: #121212;
    right: -100vw;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
  .menu__list {
    margin-bottom: 50%;
  }

  .menu hr {
    width: 90vw;
  }
}

// Menu Mobile version

@media (max-width: $mobile) {
  .menu {
    position: absolute;
    background: none;
    background-size: 180px;
    background-color: #121212;
    right: -100vw;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
  .menu__list {
    margin-bottom: 45%;
  }

  .menu hr {
    width: 90vw;
  }
}

// Menu Mobile version SE

@media (min-width: $mobileSE) and (max-width: 389px) {
  .menu {
    position: absolute;
    background: none;
    background-size: 180px;
    background-color: #121212;
    right: -100vw;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
  .menu__list {
    margin-bottom: 20%;
  }

  .menu hr {
    width: 90vw;
  }
}
