@import '../../../index.scss';

.section {
  display: flex;
  align-items: center;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 5%;
  padding-bottom: 5%;
  @media (min-width: 1300px) {
    max-width: 1300px;
  }
  @media ((min-width: $tablet) and (max-width: 1300px)) {
    max-width: 90vw;
  }
  @media (min-width: $tablet) {
    gap: 10%;
  }
  @media (max-width: $tablet) {
    justify-content: center;
  }

  &.left {
    background-position: right center;
    @media (min-width: $tablet) {
      flex-direction: row-reverse;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &.right {
    background-position: left center;
    @media (min-width: $tablet) {
      flex-direction: row;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }

  & .image {
    position: relative;
    transition: transform 0.8s ease;
    z-index: 0;
    object-fit: contain;
    @media (min-width: $tablet) and (max-width: 1200px) {
      width: 40vw;
    }
    @media (max-width: $tablet) {
      margin-top: 7%;
      width: 70vw;
    }

    &:hover {
      @media (min-width: $tablet) {
        transform: scale(1.1, 1.1);
      }
    }
  }

  & .text {
    @media (min-width: 1400px) {
      width: 520px;
    }
    margin: 20px;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 180%;
    color: #484848;
    @media ((min-width: $tablet) and (max-width: 1300px)) {
      line-height: 150%;
    }
    @media (min-width: $tablet) {
      margin-bottom: 7%;
      margin-top: 0;
    }
    @media (max-width: $tablet) {
      margin-bottom: 5%;
      margin-top: 0;
    }
  }
}

.title__content {
  display: flex;
  gap: 20px;
  margin: 20px;
  align-items: center;
  margin-bottom: 6%;

  & hr {
    background-color: #b82428;
    height: 85px;
    border: none;
    width: 16px;
    margin: 0;
    @media ((min-width: $tablet) and (max-width: 1300px)) {
      height: 65px;
    }
    @media ((min-width: $mobile) and (max-width: 1023px)) {
      width: 12px;
      height: 55px;
    }
    @media ((min-width: 375px) and (max-width:389px)) {
      width: 12px;
      height: 55px;
    }
  }
  & h2 {
    font-weight: 600;
    letter-spacing: 0.135em;
    color: #242222;
    @media ((min-width: 375px) and (max-width:1300px)) {
      font-size: 1.7rem;
    }
  }
}

picture {
  display: flex;
  justify-content: center;
}
