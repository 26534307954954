#hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-flow: column nowrap;
  justify-content: center; /* см. ниже */
  align-items: center;
  z-index: 99;
  /* Чтобы окно не прилипало к границе
    браузера установим отступы */
  padding: 30px 0;
}

.hide {
  opacity: 0;
  display: none;
}

@keyframes ani {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#hystmodal__window {
  margin: 50px 0;
  border-radius: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  height: 250px;
  width: 650px;
  max-width: 100%;
  overflow: visible;
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  transform: scale(0.9);
  opacity: 1;
  p {
    margin-top: 0%;
    text-align: center;
    font: 2rem 'Inter', sans-serif;
  }
  button {
    margin-top: 3%;
    margin-left: 93%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
  #image {
    text-align: center;
    img {
      margin-top: 2.2%;
      margin-bottom: 3.9%;
    }
  }
}

#hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

#hystmodal--active {
  visibility: visible;
}

#hystmodal--active #hystmodal__window {
  transform: scale(1);
  opacity: 1;
}

#hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

#hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}
