@import '../../../index.scss';

body {
  margin: 0;
}

button {
 cursor: pointer;
}

a {
  cursor: pointer;
}

#progress__list {
  list-style: none;
}

#hero__section {
  display: flex;
  text-align: center;
  font-family: 'Inter', sans-serif;
  color: #f4f4f4;
  height: 100vh;
  #hero__section__content {
    margin: auto;
  }
  & p {
    margin: 0;
    margin-bottom: 2%;
    color: white;
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: 0.05em;
    line-height: 36px;
    & span {
      color: rgba(190, 43, 47, 1);
    }
  }
  & h1 {
    margin: 0 auto;
    margin-bottom: 8%;
    width: 53%;
    font-weight: 700;
    @media (min-width: $tablet) {
      font-size: 3rem;
    }
    line-height: 73px;
    color: #f4f4f4;
  }
  a {
    cursor: pointer;
  }
  & button {
    width: 224px;
    height: 54px;
    background-color: transparent;
    border: solid rgba(190, 43, 47, 1) 3px;
    border-radius: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    color: #f4f4f4;
    transition: transform 1s ease;
    &:hover {
      transform: scale(1.1, 1.1);
      background-color: rgba(190, 43, 47, 1);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.39);
    }
  }
}

#hero__section__background {
  min-height: 400px;
  background-size: cover;
}

// Desktop version

@media ((min-height: 901px) and (max-height: 1080px)) {
  #hero__section {
    @media ((min-width: 1000px) and (max-width: 1200px)) {
      & h1 {
        width: 85%;
      }
    }
    @media ((min-width: 1201px) and (max-width: 1365px)) {
      & h1 {
        width: 75%;
      }
    }
    @media ((min-width: 1366px) and (max-width: 1465px)) {
      & h1 {
        width: 70%;
      }
    }
    @media ((min-width: 1466px) and (max-width: 1579px)) {
      & h1 {
        width: 65%;
      }
    }
    @media ((min-width: 1580px) and (max-width: 1920px)) {
      & h1 {
        width: 56.5%;
      }
    }
  }
}

//Intermediate from tablet

@media ((min-width: 1401px) and (max-width: 1600px)) {
  #hero__section {
    & h1 {
      width: 65%;
    }
  }
}

@media ((min-width: 1200px) and (max-width: 1400px)) {
  #hero__section {
    & h1 {
      width: 75%;
      margin-bottom: 10%;
    }
  }
}

// Tablet version

@media ((min-width: $tablet) and (max-width:1199px)) {
  #hero__section {
    & p {
      margin-bottom: 5%;
    }
    & h1 {
      width: 85%;
      margin-bottom: 10%;
    }
  }
}
@media ((min-height: 668px) and (max-height: 900px)) {
  #hero__section {
    & h1 {
      width: 85%;
    }
  }
}

//  Intermediate version from mobile

@media ((min-width: 651px) and (max-width: 1023px)) {
  #hero__section {
    & p {
      margin-bottom: 5%;
      font-size: 1.5rem;
    }
    & h1 {
      font-size: 2.5rem;
      width: 85%;
      margin-bottom: 8%;
      line-height: 65px;
    }
  }
}

@media ((min-width: 501px) and (max-width: 650px)) {
  #hero__section {
    & p {
      margin-bottom: 5%;
      font-size: 1.3rem;
    }
    & h1 {
      font-size: 2rem;
      width: 85%;
      margin-bottom: 15%;
      line-height: 50px;
    }
  }
}

@media ((min-height: 501px) and (max-height: 667px)) {
  #hero__section {
    & p {
      margin-bottom: 3%;
      font-size: 1.5rem;
    }
    & h1 {
      font-size: 2.2rem;
      line-height: 50px;
      width: 85%;
      margin-bottom: 5%;
    }
  }
}

// Mobile Version

@media ((min-width: $mobile) and (max-width: 500px)) {
  #hero__section {
    & p {
      margin-bottom: 7%;
      font-size: 1.3rem;
    }
    & h1 {
      font-size: 1.8rem;
      line-height: 45px;
      width: 80%;
      margin-bottom: 25%;
    }
  }
}

@media ((min-width: 375px) and (max-width: 389px)) {
  #hero__section {
    & p {
      margin-bottom: 7%;
      font-size: 1.3rem;
    }
    & h1 {
      font-size: 1.8rem;
      line-height: 45px;
      width: 80%;
      margin-bottom: 20%;
    }
  }
}

@media ((min-height: 360px) and (max-height: 500px)) {
  #hero__section {
    & p {
      margin-top: 7%;
      margin-bottom: 2%;
      font-size: 1.2rem;
    }
    & h1 {
      font-size: 1.9rem;
      line-height: 45px;
      width: 90%;
      margin-bottom: 4%;
    }
    & button {
      width: 190px;
      height: 44px;
    }
  }
}
