@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&family=Inter:wght@100;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); // Font Inter
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@300;400;700&family=Inter:wght@700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); // Font Inria Sans
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:wght@700&family=Inter:wght@700&family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); // Open Sans

// Основной текст - 16px - p
// Оглавление в домашней странице - 60px - h1
// Оглавление в разделах - 40px - h2, в разделе Начать проект - 60px - h2

$h1: 3.75rem 'Inter', sans-serif;
$h2: 2.4rem 'Inter', sans-serif;
$p: 1rem 'Inria Sans', sans-serif;

// Size device
$tablet: 1024px;
$mobile: 390px;
$mobileSE: 320px;

#root {
  max-width: 1920px;
  margin: 0 auto;
}

.App {
  text-align: center;
  background-color: #f4f4f4;
}

h2 {
  font: $h2;
}

html {
  min-width: 320px;
  scrollbar-width: none;
}

body {
  font: $p;
  background-color: #f4f4f4;
}

body::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.hidden {
  display: none;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
