#container {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  & li {
    margin-right: 9px;
    margin-left: 9px;
    img {
      width: 35px;
    }
  }
  a {
    cursor: pointer;
  }
}
