@import '../../../index.scss';

#start__project {
  @media only screen and (min-width: 1350px) {
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .start__project__step {
    align-items: center;
    margin: 50px 20px;
    gap: 15px;

    &.mobile {
      @media (min-width: 1300px) {
        display: none;
      }
      @media (max-width: 600px) {
        justify-content: center;
      }
      @media (max-width: 1300px) {
        display: none;
      }
    }
    &.desktop {
      @media ((min-width: 420px)) {
        display: flex;
      }
      @media (max-width: 420px) {
        display: block;
      }
    }

    #step__text {
      display: flex;
      justify-content: initial;
      align-items: center;
      gap: 15px;
      @media (max-width: 420px) {
        margin-bottom: 15px;
      }
    }

    img {
      height: 25px;
      width: 25px;
    }
    p {
      font-weight: 700;
      letter-spacing: 0.135em;
      color: #b82428;
      flex-wrap: nowrap;
    }
  }

  fieldset {
    border: none;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 25px;
    @media (max-width: 1350px) {
      flex-wrap: wrap;
      max-width: 800px;
      margin-top: 1rem;
    }
    label {
      @media (max-width: 500px) {
        scale: 0.8px;
        width: 136px;
        height: 136px;
      }
    }
    button[type='button'] {
      @media (max-width: 500px) {
        scale: 0.8px;
        width: 136px;
        height: 136px;
      }
    }
  }
}

#progress__bar {
  height: 8px;
  background: #e8e8e8;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 172px;
}

.progress__bar__inner {
  background-color: #b82428;
  height: 100%;
  border-radius: 30px;
  &.x1 {
    transition: all 0.7s;
    width: 25%;
  }
  &.x2 {
    transition: all 0.7s;
    width: 50%;
  }
  &.x3 {
    transition: all 0.7s;
    width: 75%;
  }
  &.x4 {
    transition: all 0.7s;
    width: 100%;
  }
}

#start__project__title_content {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 1300px) {
    margin: 0 20px 40px;
  }
  @media (max-width: 1300px) {
    margin: 40px 20px 20px;
  }

  hr {
    margin: 0;
    height: 90px;
    width: 16px;
    border-radius: 0;
    background-color: rgb(9, 9, 9);
    @media ((min-width: 600px ) and (max-width:859px)) {
      height: 80px;
      width: 16px;
    }
    @media (max-width: 599px) {
      height: 70px;
      width: 16px;
    }
  }

  h2 {
    font-weight: 600;
    letter-spacing: 0.135em;
    @media (min-width: 859px) {
      font-size: 3.5rem;
    }
    @media ((min-width: 600px ) and (max-width:859px)) {
      font-size: 3.2rem;
    }
    @media ((min-width: 421px) and (max-width:599px)) {
      font-size: 2.5rem;
    }
    @media ((min-width: 375px ) and (max-width:450px)) {
      font-size: 2.1rem;
    }
  }
}

#start__project__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

form {
  @media (max-width: 1350px) {
    margin: 0 auto;
  }
  & button {
    border: none;
    background-color: #f4f4f4;
  }
  & label {
    align-items: center;
  }
}

#start__project__progress__list {
  display: flex;
  gap: 45px;
  align-items: center;
  align-self: baseline;
  text-align: left;
  @media (max-width: 1350px) {
    display: none;
  }

  hr {
    border: none;
    background-color: #b82428;
    width: 2px;
    height: 230px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #00000057;
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
}

#start__project__progress__content {
  // margin-left: 65px;
  & h3 {
    margin-top: 0;
    padding: 0 15px 0;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 1.25rem;
    letter-spacing: 0.065em;
  }
  & li {
    margin-top: 15px;
    padding: 0 15px 0;
  }
  & .marked {
    color: #b82428;
    list-style-type: disc;
    font-weight: 600;
  }
}

#contact_form {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  @media (min-width: 1351px) {
    margin: 0 20px 0;
  }
  @media (min-width: 860px) {
    width: 800px;
  }
  @media (max-width: 860px) {
    width: 90vw;
    margin: 0 auto;
  }
}

#contact_form_content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  @media (min-width: 860px) {
    gap: 50px;
  }
  @media (max-width: 860px) {
    flex-direction: column;
  }

  img {
    height: 25px;
    width: 25px;
  }
  label {
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-align: left;
    input {
      display: block;
      // padding-left: 15px;
      margin-top: 20px;
      padding: 0 15px;
      border: none;
      background-color: #f4f4f4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 5px rgba(0, 0, 0, 0.15);
      border-radius: 15px;

      &:focus-visible {
        outline: 2px solid #b82428;
      }
      &:placeholder {
        font-family: 'Open Sans', sans-serif;
        letter-spacing: 0.5px;
        padding: 0 15px;
      }
      &:placeholder-shown {
        letter-spacing: 0.8px;
        padding: 0 15px;
      }
    }
  }
  & p {
    display: flex;
    gap: 5px;
    margin-bottom: 0px;
    align-items: center;
    color: #b82428;
    font-weight: 500;
    font-size: 0.9rem;
  }
  .name_input {
    color: #000000;
    font-size: 1rem;
    letter-spacing: 0.5px;
    @media (max-width: 859px) {
      margin-top: 3%;
      color: #000000 !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
    }
  }
  & img {
    // margin-right: 10px;
  }
  .contact_form_block {
    min-height: 150px;
    @media (min-width: 860px) {
      width: 200px;
    }
    @media (max-width: 860px) {
      margin: 0 auto;
    }

    label {
      @media (max-width: 859px) {
        display: block;
      }

      input {
        background-repeat: no-repeat;
        @media (min-width: 860px) {
          width: 183px;
          height: 45px;
        }
        @media (max-width: 860px) {
          height: 50px;
          width: 70vw;
          justify-content: space-around;
        }
      }

      .name_input {
        @media (max-width: 859px) {
          margin-top: 3%;
          color: #000000 !important;
          font-weight: 600 !important;
          font-size: 1rem !important;
        }
      }
    }

    &:nth-child(1) {
      input {
        // background: right 10px bottom 50% no-repeat
        //   url('../../../resources/icons/yourContact/nameUser.svg');
        background-image: url('../../../resources/icons/yourContact/nameUser.svg');
        background-size: 20px 20px;
      }
    }
    &:nth-child(2) {
      input {
        // background: right 10px bottom 50% no-repeat
        //   url('../../../resources/icons/yourContact/telephoneNumber.svg');
        background-image: url('../../../resources/icons/yourContact/telephoneNumber.svg');

        background-size: 20px 20px;
      }
    }
    &:nth-child(3) {
      input {
        // background: right 10px bottom 50% no-repeat
        //   url('../../../resources/icons/yourContact/email.svg');
        background-image: url('../../../resources/icons/yourContact/email.svg');

        background-size: 20px 20px;
      }
    }
  }
}

input[type='submit'] {
  margin-top: 20px;
  @media (min-width: 860px) {
    width: 223px;
    height: 50px;
  }
  @media (max-width: 860px) {
    width: 70vw;
    height: 50px;
  }
}

#contact_form_information_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  #contact_form_information {
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.5px;
    textarea {
      border: none;
      resize: none;
      margin-top: 25px;
      margin-bottom: 25px;
      padding: 20px 15px 0;
      height: 150px;
      border-radius: 20px;
      background: #f3f3f3;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 5px rgba(0, 0, 0, 0.15);
      @media (min-width: 860px) {
        width: 670px;
        margin-bottom: 6% !important;
      }
      @media (max-width: 860px) {
        width: 75vw;
        margin-bottom: 6% !important;
      }

      &::placeholder {
        color: rgb(159, 159, 159);
        letter-spacing: 0.5px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1rem;
      }
      &:placeholder-shown {
        padding: 20px 15px 0;
      }
      &:focus-visible {
        outline: 2px solid #b82429;
      }
    }
  }
  & p {
    color: #c4c4c4;
    font-size: 13px;
    letter-spacing: 0.065em;
    margin: 20px auto;
    text-align: center;
    @media (max-width: 428px) {
      max-width: 320px;
    }
  }

  & #contact_textarea_name {
    margin: 0;
    color: #000000;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }

  input {
    border: none;
    // height: 55px;
    // width: 215px;
    margin-bottom: 15px;
    background-color: #b82428;
    border-radius: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.1em;
    color: #f4f4f4;
    &:hover {
      box-shadow: 0px 0px 22px 2px rgba(0, 0, 0, 0.31);
      transition: all 0.7s;
    }
    &:disabled {
      background-color: #45454537;
    }
    &:disabled:hover {
      box-shadow: none;
    }
  }
}
