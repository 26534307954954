.loader_container {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
}

.loader {
  margin: auto;
  border: 10px solid #eeeeee;
  border-radius: 50%;
  border-top: 10px solid #b82428;
  width: 100px;
  height: 100px;
  animation: spinner 5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
