#footer {
  display: flex;
  justify-content: center;
  color: black;
  min-height: 65px;
  margin-top: 80px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 35px;
  }
}
